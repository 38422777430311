import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import Login from "../views/login.vue";
const routes = [{
        path: "/",
        name: "login",
        component: Login,
    },
    {
        path: "/index",
        name: "index",
        //路由级别的代码分割
        //这会为这个路由生成一个单独的块(about.[hash].js)
        //当路由被访问时，它会被懒加载。
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/index.vue"),
    },
];
const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(process.env.BASE_URL),
    //history createwenhashistory
    // hash: createWebHistory(process.env.BASE_URL),
    // mode: 'history',
    // base: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;