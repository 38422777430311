import axios from "./axios";

export const logins = (data) => {
    debugger;
    return axios({
        url: '/api/login/getLoginByNameAndPwd',
        method: 'get',
        data
    })
}
export default { logins }