import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import ElementUI from 'element-ui';
import ElementPlus from "element-plus";
import echarts from "echarts";
import http from '@/api/config.js'
import axios from "axios";
import 'element-plus/dist/index.css'
import locale from "element-plus/lib/locale/lang/zh-cn"
import VueCookies from 'vue-cookies'
// import locale from element plus lib locale lang zh-cn
// import 'element-plus/lib/theme-chalk/index.css';
// import Axios from "axios";
// Vue.prototype.$axios = Axios;
// 引入echart
// Vue.prototype.$echarts = echarts;
// 全局配置
import moment from "moment";
createApp(App).config.globalProperties.$moment = moment;
createApp(App).use(store).use(router).use(ElementPlus, { locale }).use(echarts).use(VueCookies).mount("#app");
createApp(App).config.globalProperties.$http = 'http'
createApp(App).config.globalProperties.$http = axios;
// app.config.globalProperties.$echarts = echarts