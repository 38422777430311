<template>
  <div>
    <div class="app" style="position: relative;top: 50%;">
      <div class="wp animsition">
        <div class="boardLogin">
          <p class="logo">IMAGE PLATFORM</p>
          <!-- <form> -->
          <div class="inpGroup">
            <div class="ingo">
              <span class="loginIco1" style="margin-top: -3px;"></span>
            </div>
            <!-- <input type="text" name placeholder="请输入您的用户名" /> -->
            <el-input v-model="input1" placeholder="USERNAME"></el-input>
          </div>
          <!-- 
            <div class="prompt">
              <p class="error">用户名错误或不存</p>
            </div>
          -->
          <div class="inpGroup">
            <div class="ingo">
              <span class="loginIco2" style="margin-top: -3px;"></span>
            </div>
            <!-- <input type="password" name placeholder="请输入您的密码"/> --> 
            <el-input
              v-model="input2"
              placeholder="PIN"
              show-password
              @keyup.enter.native="keyup()"
            ></el-input>
          </div>
          <!-- 
            <div class="prompt">
              <p class="success">输入正确</p>
            </div>
          -->
          <!-- <button class="submit">登录</button> -->
          <!-- <button class="animsition-link submit">登录</button> -->
          <!-- <el-button type="primary" class="animsition-link submit" @click="submitForm()">登录</el-button> -->
          <el-button type="primary" class="animsition-link submit" @click="submitForm()">LOGIN</el-button>
          <!-- <el-button type="text" @click="open">点击打开 Message Box</el-button> -->
          <!-- </form> -->
        </div>
      </div>
    </div>
    <div id="particles-js"></div>
  </div>
</template>
<script>
// import vue  from 'https://cdn.bootcss.com/vue/2.6.10/vue.js';
import { reactive, onMounted } from "vue";
import { logins } from "@/http/index.js";
import axios from "../http/axios";
export default {
  data() {
    return {
      loading: true,
      input1: "",
      input2: ""
    };
  },
  components: {},
  mounted() {
    let shuint = ["88", "99"];
    // let urlas = 'https://wsq.szlh.org.cn/fds/group1/M00/00/48/wKgno2Qrwb2AMKHvAAB1cxhTDk4878.png'
    // let astoken='068b6e18147b9b1e2cf9e52372628772'
    // consloe.log()
  },
  methods: {
    keyup() {
      this.loginButon();
    },
    submitForm() {
      this.loginButon(); 
    },
    loginButon() {
      axios
        .get(
          // "http://47.106.126.250:18980/szjc/api/login/getLoginByNameAndPwd",
          "http://en.szkekj.com/monitorPlatform/api/login/getLoginByNameAndPwd",
          // "https://cloud2.szkekj.com/iceplatform/api/login/getLoginByNameAndPwd",
          {
            params: {
              //传入的参数
              username: this.input1,
              password: this.input2
            }
          }
        )
        .then(response => {
          if (response.code != 2000) {
            //     this.$notify({
            //   title: '成功',
            //   message: '这是一条成功的提示消息',
            //   type: 'success'
            // });
            this.$notify.error({
              title: "mistake",
              message: "Account password error！"
            });
          } else {
            localStorage.setItem("sessionid", response.data.user.sessionid);
            localStorage.setItem("userCode", response.data.user.user.userCode);
            localStorage.setItem("officeCode", response.data.officeCode);
            var sessid = response.data.user.sessionid;
            this.$cookies.set("jeesite.session.id", sessid, "3600s");
            // Cookies.set('jeesite.session.id', sessid, {
            // 	path:'szjc'
            // })
            this.$notify({
              title: "Successful",
              message: "Login successful！",
              type: "success"
            });
            // window.location.href='index.html';
            // this.$router.replace({
            //   path: "/index"
            // });
            this.$router.push({
              name: "index"
            });
          }
        });
      // const data={
      // username: this.input1,
      // password: this.input2
      //     };
      //     logins(data).then(res => {
      //     }).catch(err => {
      //     })
      // this.axios({
      //   method: "get",
      //   url: "http://47.106.126.250:18980/szjc/api/login/getLoginByNameAndPwd",
      //   data: {
      //          username: this.input1,
      // 					password: this.input2
      //   }
      // }).then(function(res) {
      //   // if (res.statusText == "OK") {
      //   // alert("登陆成功");
      //   // }
      // });
      // this.$axios.get("http://47.106.126.250:18980/szjc/api/login/getLoginByNameAndPwd",{
      //   params: {
      //       //传入的参数
      //       username: this.input1,
      // 					password: this.input2
      //     }
      // })
      // .then(response => {
      //   response.set('Access-Control-Allow-Origin','*')
      // });
    }
  }
};
</script> 
<!-- 
    <link rel="stylesheet" type="text/css" href="css/reset.css" />
    <link rel="stylesheet" type="text/css" href="css/scanboardLogin.css" />
    <link rel="stylesheet" type="text/css" href="css/animsition.css" />
    <link href="https://cdn.bootcss.com/element-ui/2.8.2/theme-cha0lk/index.css" rel="stylesheet">
-->
<style>
@import "../store/scanboardLogin.css";
.boardLogin .inpGroup input {
  border: 0ch !important;
  background: inherit !important;
}
.el-input__wrapper {
  border: 0ch !important;
}
</style> 